
import dictionaryData from "@/views/dictionary/DictionaryData.vue";
import {ref} from 'vue';
import {
  addDictionary,
  deleteDictionary,
  getDictionary,
  refreshDictionaryCache,
  updateDictionary
} from "@/api/dictionary";
import {useStore} from 'vuex';
import {ElMessage, ElMessageBox} from 'element-plus';
import DictionaryData from "@/views/dictionary/DictionaryData.vue";

export default {
  components: {DictionaryData},
  setup(){
    const formVisible = ref(false);

    const validate: any = ref(null);

    const loading = ref(false);

    const store = useStore();

    const formSize = ref('large');

    const formRules = {
      dictName: [
        {
          required: true,
          message: '请输入字典名',
          trigger: 'blur',
        },
        {
          max: 20,
          message: '字典名称不能超过20字符',
          trigger: 'blur',
        }
      ],
      dictCode: [
        {
          required: true,
          message: '请输入字典代码',
          trigger: 'blur',
        },
        {
          max: 20,
          message: '字典代码不能超过20字符',
          trigger: 'blur',
        },
      ],
      description: [
        {
          max: 50,
          message: '描述不能超过50个字符!',
          trigger: 'blur',
        }
      ],
    };

    const formTitle = ref();

    //字典数据弹窗
    const dictDataModal = ref(false);

    const dictDataInfo = ref();

    const dictCode = ref();

    const getStatusDict = (status) => {
      let label = '';
      let type = '';
      if(status == '0'){
        label = '停用';
        type = 'warning';
      }else{
        label = '正常';
        type = 'success';
      }
      return {"label": label,"type": type};
    };

    const dictStatus = [
      {
        status: '0',
        label: '停用',
      },
      {
        status: '1',
        label: '正常',
      }
    ];

    //适配移动端
    if(store.state.app.isMobile){
      formSize.value = 'mini';
    }else{
      formSize.value = 'large';
    }

    const table:any = ref({
      page: 1,        // 当前在第几页
      pageSize: 10,   // 一页显示多少
      total: 0,       // 数据总量
      loading: false,  // 加载中
      data: [],       // 表格数据
      isMobile: false,// 表格是否移动端
    });

    const searchParams: any = ref({
      dictName: '',
      dictCode: '',
      description: '',
      status: '',
    });

    const dictForm: any = ref({});

    table.value.isMobile = store.state.app.isMobile;

    //获取字典列表
    const listDictionary = () => {
      table.value.loading = true,
          getDictionary({
            page: table.value.page,
            pageSize: table.value.pageSize,
          }).then((res: any) => {
            table.value.data = res.data.list;
            table.value.total = res.data.total;
            table.value.loading = false;
          });
    }

    listDictionary();

    const handleSizeChange = (pageSize: number) => {
      table.value.pageSize = pageSize;
      listDictionary();
    }

    const handleCurrentChange = (page: number) => {
      table.value.page = page;
      listDictionary();
    }

    const searchDictionary = () => {
      table.value.loading = true,
          getDictionary({
            page: table.value.page,
            pageSize: table.value.pageSize,
            dictName: searchParams.value.dictName,
            dictCode: searchParams.value.dictCode,
            description: searchParams.value.description,
            status: searchParams.value.status,
          }).then((res: any) => {
            table.value.data = res.data.list;
            table.value.total = res.data.total;
            table.value.loading = false;
          });
    }

    const handleDelete = (id) => {
      deleteDictionary(id).then((res: any) => {
        if(!res.errCode) {
          ElMessage.success("删除成功!");
          listDictionary();
        }else{
          ElMessage.error(res.detail);
        }
      });
    }

    const handleEditClick = (row) => {
      formTitle.value = '修改字典';
      dictForm.value = JSON.parse(JSON.stringify(row)); //消除row的响应性
      formVisible.value = true;
    }

    const handleAddClick = () => {
      formTitle.value = '添加字典';
      dictForm.value = {};
      formVisible.value = true;
    }

    const handleSubmit = () => {
      //添加字典
      if(!dictForm.value.id) {
        validate.value.validate((valid) => {
          if(valid) {
            loading.value = true;
            addDictionary(dictForm.value).then((res: any) => {
              if(!res.errCode){
                ElMessage.success('新建字典成功!');
                formVisible.value = false;
                listDictionary();
              }else{
                ElMessage.error(res.detail);
              }
              loading.value = false;
            }).catch(() => {
              loading.value = false;
            });
          }
        });
        //修改字典
      }else {
        validate.value.validate((valid) => {
          if(valid){
            loading.value = true;
            updateDictionary(dictForm.value).then((res: any) => {
              if(!res.errCode) {
                ElMessage.success('更新字典成功!');
                formVisible.value = false;
                listDictionary();
              } else {
                ElMessage.error(res.detail);
              }
              loading.value = false;
            }).catch(() => {
              loading.value = false;
            });
          }
        });
      }

    }

    //刷新缓存
    const handleRefreshCache = () => {
      ElMessageBox.confirm('确认刷新缓存？', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      }).then(function() {
        refreshDictionaryCache().then((res: any) => {
          if(!res.errCode) {
            ElMessage.success('刷新缓存成功!');
            listDictionary();
          } else {
            ElMessage.error(res.detail);
          }
          loading.value = false;
        }).catch(() => {
          loading.value = false;
        });
      });

    }

    const handleModalOpen = (scope) => {
      dictDataModal.value = true;
      dictDataInfo.value = scope.row.dictName + ': ' + scope.row.dictCode;
      dictCode.value = scope.row.dictCode;
    }


    return {
      table,
      handleSizeChange,
      handleCurrentChange,
      searchParams,
      searchDictionary,
      formSize,
      dictStatus,
      getStatusDict,
      handleDelete,
      handleEditClick,
      handleSubmit,
      formVisible,
      dictForm,
      loading,
      formRules,
      validate,
      formTitle,
      handleAddClick,
      handleRefreshCache,
      dictDataModal,
      dictDataInfo,
      handleModalOpen,
      dictCode
    };
  }
}
const validate = ref(null);
