
import {ref, watch} from 'vue';
import {
  addDictionaryData,
  deleteDictionaryData,
  getDictionaryDataByCode,
  updateDictionaryData
} from "@/api/dictionaryData";
import {useStore} from 'vuex';
import {ElMessage} from 'element-plus';

export default {
  props: {
    code: String,
  },
  setup(props){

    const dictCode = ref(props.code);
    watch( () => props.code,
        (newValue) => {
          dictCode.value = newValue;
          listDictionary();
    });

    const formVisible = ref(false);

    const validate: any = ref(null);

    const loading = ref(false);

    const store = useStore();

    const formSize = ref('large');

    const formRules = {
      dictLabel: [
        {
          required: true,
          message: '请输入数据标签',
          trigger: 'blur',
        },
        {
          max: 20,
          message: '数据标签不能超过20字符',
          trigger: 'blur',
        }
      ],
      dictValue: [
        {
          required: true,
          message: '请输入数据值',
          trigger: 'blur',
        },
        {
          max: 20,
          message: '数据值不能超过20字符',
          trigger: 'blur',
        },
      ],
      description: [
        {
          max: 50,
          message: '描述不能超过50个字符!',
          trigger: 'blur',
        }
      ],
    };

    const formTitle = ref();

    const getStatusDict = (status) => {
      let label = '';
      let type = '';
      if(status == '0'){
        label = '停用';
        type = 'warning';
      }else{
        label = '正常';
        type = 'success';
      }
      return {"label": label,"type": type};
    };

    const dictStatus = [
      {
        status: '0',
        label: '停用',
      },
      {
        status: '1',
        label: '正常',
      }
    ];

    //适配移动端
    if(store.state.app.isMobile){
      formSize.value = 'mini';
    }else{
      formSize.value = 'large';
    }

    const table:any = ref({
      page: 1,        // 当前在第几页
      pageSize: 10,   // 一页显示多少
      total: 0,       // 数据总量
      loading: false,  // 加载中
      data: [],       // 表格数据
      isMobile: false,// 表格是否移动端
    });

    const dictForm: any = ref({});

    table.value.isMobile = store.state.app.isMobile;

    //获取字典数据列表
    const listDictionary = () => {
        dictForm.value.dictCode = dictCode.value;
        table.value.loading = true;
        getDictionaryDataByCode(dictCode.value).then((res: any) => {
          table.value.data = res.data;
          table.value.loading = false;
        });
    }

    listDictionary();

    const handleDelete = (id) => {
      deleteDictionaryData(id).then((res: any) => {
        if(!res.errCode) {
          ElMessage.success("删除成功!");
          listDictionary();
        }else{
          ElMessage.error(res.detail);
        }
      });
    }

    const handleEditClick = (row) => {
      formTitle.value = '修改字典数据';
      dictForm.value = JSON.parse(JSON.stringify(row)); //消除row的响应性
      formVisible.value = true;
    }

    const handleAddClick = () => {
      formTitle.value = '添加字典数据';
      dictForm.value = {};
      formVisible.value = true;
    }

    const classTypeOptions = [
      {
        value: "success",
        label: "成功"
      },
      {
        value: "info",
        label: "信息"
      },
      {
        value: "warning",
        label: "警告"
      },
      {
        value: "danger",
        label: "危险"
      }
    ];

    const handleSubmit = () => {
      dictForm.value.dictCode = dictCode.value;
      //添加字典数据
      if(!dictForm.value.id) {
        validate.value.validate((valid) => {
          if(valid) {
            loading.value = true;
            addDictionaryData(dictForm.value).then((res: any) => {
              if(!res.errCode){
                ElMessage.success('新建字典数据成功!');
                formVisible.value = false;
                listDictionary();
              }else{
                ElMessage.error(res.detail);
              }
              loading.value = false;
            }).catch(() => {
              loading.value = false;
            });
          }
        });
        //修改字典数据
      }else {
        validate.value.validate((valid) => {
          if(valid){
            loading.value = true;
            updateDictionaryData(dictForm.value).then((res: any) => {
              if(!res.errCode) {
                ElMessage.success('更新字典数据成功!');
                formVisible.value = false;
                listDictionary();
              } else {
                ElMessage.error(res.detail);
              }
              loading.value = false;
            }).catch(() => {
              loading.value = false;
            });
          }
        });
      }

    }


    return {
      table,
      formSize,
      dictStatus,
      getStatusDict,
      handleDelete,
      handleEditClick,
      handleSubmit,
      formVisible,
      dictForm,
      loading,
      formRules,
      validate,
      formTitle,
      handleAddClick,
      classTypeOptions,
    };
  }
}
const validate = ref(null);
